import React from 'react';
import { ArrowLeft } from 'lucide-react';
import CategoryGroupCard from '../components/CategoryGroupCard';
import { categoryGroups } from '../data/categories';

export default function SelectionPage() {
  return (
    <main className="flex-grow container mx-auto px-4 py-16">
      <div className="max-w-4xl mx-auto">
        <div className="flex items-center justify-between mb-8">
          <h1 className="text-4xl font-bold text-white">Notre sélection de livres</h1>
          <a 
            href="/" 
            className="flex items-center text-gray-400 hover:text-blue-400 transition-colors duration-200"
          >
            <ArrowLeft className="w-5 h-5 mr-2" />
            Retour à l'accueil
          </a>
        </div>

        <p className="text-xl text-gray-400 mb-12">
          Explorez nos catégories soigneusement sélectionnées pour trouver votre prochaine lecture passionnante
        </p>

        <div className="grid gap-6">
          {categoryGroups.map((group) => (
            <CategoryGroupCard
              key={group.slug}
              group={group}
            />
          ))}
        </div>
      </div>
    </main>
  );
}