export interface Category {
  title: string;
  description: string;
  slug: string;
  isAvailable?: boolean;
}

export interface CategoryGroup {
  title: string;
  description: string;
  slug: string;
  isAvailable?: boolean;
}

export const categoryGroups: CategoryGroup[] = [
  {
    title: "Classement par Genres Littéraires",
    description: "Explorez nos sélections par genres, de la science-fiction aux romans historiques en passant par la fantasy et les thrillers.",
    slug: "genres",
    isAvailable: true
  },
  {
    title: "Classement par Public",
    description: "Trouvez des livres adaptés à chaque âge et niveau de lecture, de la jeunesse aux lectures adultes.",
    slug: "public"
  },
  {
    title: "Classement par Périodes Historiques",
    description: "Voyagez à travers les époques avec nos sélections de livres classés par périodes historiques.",
    slug: "periodes"
  },
  {
    title: "Classement par Thématiques",
    description: "Découvrez nos sélections thématiques pour explorer des sujets spécifiques qui vous passionnent.",
    slug: "thematiques"
  },
  {
    title: "Classement par Types de Livres",
    description: "Des romans aux essais en passant par les bandes dessinées, trouvez le format qui vous convient.",
    slug: "types"
  },
  {
    title: "Classement par Auteurs Célèbres",
    description: "Explorez les œuvres des plus grands auteurs de la littérature mondiale.",
    slug: "auteurs"
  },
  {
    title: "Classement par Occasions Spéciales",
    description: "Des suggestions de lecture parfaites pour chaque moment et occasion de l'année.",
    slug: "occasions"
  },
  {
    title: "Classement par Besoins",
    description: "Trouvez des livres qui répondent à vos besoins spécifiques, qu'ils soient pratiques ou émotionnels.",
    slug: "besoins"
  },
  {
    title: "Classement par Adaptations",
    description: "Découvrez les livres qui ont inspiré vos films et séries préférés.",
    slug: "adaptations"
  },
  {
    title: "Classement par Origines Géographiques",
    description: "Voyagez à travers le monde avec des livres de différentes cultures et pays.",
    slug: "origines"
  }
];