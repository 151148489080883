import React, { useState } from 'react';
import { BookOpen } from 'lucide-react';
import { getOptimizedImageUrl } from '../utils/image';

interface Book {
  title: string;
  author: string;
  cover?: string;
  summary: string;
  link?: string;
}

const FALLBACK_IMAGE = 'https://images.unsplash.com/photo-1543002588-bfa74002ed7e?w=400&auto=format&fit=crop&q=60';

export default function BookList({ books }: { books: Book[] }) {
  return (
    <div className="grid gap-6">
      {books.map((book, index) => {
        const [isExpanded, setIsExpanded] = useState(false);
        const [imageError, setImageError] = useState(false);
        const [imageUrl, setImageUrl] = useState<string>(FALLBACK_IMAGE);
        
        return (
          <div
            key={`${book.title}-${index}`}
            className="bg-gray-800/50 backdrop-blur-sm rounded-xl shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300 border border-gray-700"
          >
            <div className="flex flex-col md:flex-row">
              <div className="relative w-full md:w-48 h-48 md:h-64 bg-gray-900 flex-shrink-0">
                {book.cover ? (
                  <img
                    className="h-full w-full object-contain md:object-cover transition-opacity duration-300"
                    src={imageUrl}
                    alt={`Couverture de ${book.title}`}
                    onError={() => setImageError(true)}
                    loading="lazy"
                  />
                ) : (
                  <div className="h-full w-full flex items-center justify-center">
                    <BookOpen className="w-12 h-12 text-gray-600" />
                  </div>
                )}
              </div>
              <div className="flex-1 p-4 md:p-6">
                <div className="flex flex-col md:flex-row justify-between items-start gap-2 mb-3">
                  <div>
                    <h3 className="text-xl font-bold text-white mb-1">{book.title}</h3>
                    <p className="text-sm text-gray-400">{book.author}</p>
                  </div>
                </div>
                
                <div className="relative">
                  <p className={`text-gray-300 mb-4 ${!isExpanded ? 'line-clamp-3' : ''}`}>
                    {book.summary}
                  </p>
                  {book.summary.length > 200 && (
                    <button
                      onClick={() => setIsExpanded(!isExpanded)}
                      className="text-blue-400 hover:text-blue-300 transition-colors duration-200 text-sm"
                    >
                      {isExpanded ? 'Voir moins' : 'Lire la suite'}
                    </button>
                  )}
                </div>
                
                {book.link && (
                  <a
                    href={book.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center px-4 py-2 bg-blue-500/10 text-blue-400 rounded-lg hover:bg-blue-500/20 transition-colors duration-200"
                  >
                    Acheter sur Amazon
                  </a>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}