import React from 'react';
import { Library, ShoppingCart, Heart, ArrowLeft } from 'lucide-react';

export default function AboutPage() {
  return (
    <main className="flex-grow container mx-auto px-4 py-16">
      <div className="max-w-3xl mx-auto">
        <div className="flex items-center justify-between mb-8">
          <h1 className="text-4xl font-bold text-white">À propos de BookFinder.io : Trouvez des idées de lecture personnalisées</h1>
          <a 
            href="/" 
            className="flex items-center text-gray-400 hover:text-blue-400 transition-colors duration-200"
          >
            <ArrowLeft className="w-5 h-5 mr-2" />
            Retour à l'accueil
          </a>
        </div>
        
        <div className="space-y-8">
          <div className="bg-gray-800/50 rounded-2xl p-8 backdrop-blur-sm border border-gray-700">
            <div className="prose prose-invert max-w-none">
              <div className="space-y-6 text-gray-300 leading-relaxed">
                <p>
                  Bienvenue sur BookFinder.io, votre destination incontournable pour découvrir des idées de lecture 
                  adaptées à vos envies. Que vous recherchiez un livre captivant, une idée de lecture inspirante 
                  ou un cadeau littéraire parfait, BookFinder.io vous accompagne pour explorer l'univers des livres 
                  de manière simple, intuitive et personnalisée.
                </p>

                <h2 className="text-2xl font-semibold text-white mt-8 mb-4">Un concept unique pour découvrir votre prochaine lecture</h2>
                <p>
                  BookFinder.io a été conçu pour simplifier votre quête du livre idéal. En décrivant vos préférences 
                  – un genre, un auteur, un thème ou même une ambiance particulière – notre outil intelligent vous 
                  propose une sélection de livres soigneusement adaptée à vos besoins. Chaque recommandation inclut 
                  un résumé détaillé, une couverture attrayante et un lien direct pour en savoir plus ou acheter en 
                  toute simplicité.
                </p>

                <div className="grid grid-cols-1 md:grid-cols-3 gap-8 my-12">
                  <div className="text-center">
                    <div className="bg-blue-500/10 p-4 rounded-xl inline-flex mb-4">
                      <Library className="w-8 h-8 text-blue-400" />
                    </div>
                    <h3 className="text-lg font-semibold text-white mb-2">Découverte Personnalisée</h3>
                    <p className="text-gray-400">Trouvez des livres parfaitement adaptés à vos goûts et intérêts.</p>
                  </div>
                  
                  <div className="text-center">
                    <div className="bg-blue-500/10 p-4 rounded-xl inline-flex mb-4">
                      <ShoppingCart className="w-8 h-8 text-blue-400" />
                    </div>
                    <h3 className="text-lg font-semibold text-white mb-2">Achats Simplifiés</h3>
                    <p className="text-gray-400">Achetez facilement vos livres via nos liens Amazon sécurisés.</p>
                  </div>
                  
                  <div className="text-center">
                    <div className="bg-blue-500/10 p-4 rounded-xl inline-flex mb-4">
                      <Heart className="w-8 h-8 text-blue-400" />
                    </div>
                    <h3 className="text-lg font-semibold text-white mb-2">Projet Soutenu</h3>
                    <p className="text-gray-400">Contribuez au développement de BookFinder.io sans frais supplémentaires.</p>
                  </div>
                </div>

                <h2 className="text-2xl font-semibold text-white mt-8 mb-4">Pourquoi choisir BookFinder.io ?</h2>
                <ul className="list-disc pl-6 space-y-2">
                  <li>Des recommandations personnalisées : Grâce à notre approche unique, découvrez des livres qui correspondent parfaitement à vos goûts et à vos attentes.</li>
                  <li>Un large éventail de genres : Science-fiction, fantasy, thrillers, romans historiques, développement personnel, jeunesse… trouvez des idées de lecture quel que soit votre style.</li>
                  <li>Inspiration et praticité : Besoin d'une idée de lecture rapide, d'un roman à dévorer ou d'un livre à offrir ? BookFinder.io est là pour vous guider.</li>
                </ul>

                <h2 className="text-2xl font-semibold text-white mt-8 mb-4">Votre source d'idées de livres et de lecture</h2>
                <p>
                  Nous savons que face à l'immensité des livres disponibles, il peut être difficile de choisir 
                  votre prochaine lecture. C'est pourquoi BookFinder.io est bien plus qu'un simple moteur de 
                  recherche de livres : c'est un véritable compagnon littéraire, conçu pour inspirer chaque 
                  lecteur, qu'il soit novice ou passionné. Notre mission ? Transformer votre recherche en une 
                  expérience enrichissante, où chaque découverte devient une aventure.
                </p>

                <h2 className="text-2xl font-semibold text-white mt-8 mb-4">Pourquoi choisir la lecture avec BookFinder.io ?</h2>
                <p>
                  Chez BookFinder.io, nous croyons que la lecture est un voyage – une opportunité d'explorer de 
                  nouveaux mondes, de nourrir votre esprit et d'enrichir votre quotidien. Avec notre plateforme, 
                  nous rendons la lecture plus accessible et engageante, en vous offrant une sélection sur mesure 
                  qui répond à vos envies et besoins.
                </p>

                <h2 className="text-2xl font-semibold text-white mt-8 mb-4">Rejoignez la communauté de BookFinder.io</h2>
                <p>
                  Que vous soyez en quête d'une idée de lecture pour vous évader, d'un livre marquant pour élargir 
                  vos horizons ou d'un cadeau parfait à offrir, BookFinder.io est votre allié. Laissez-nous vous 
                  inspirer et vous guider vers des livres qui sauront captiver votre imagination et enrichir votre vie.
                </p>

                <p className="text-lg font-semibold mt-6">
                  Avec BookFinder.io, trouvez des idées de livres et votre prochaine grande lecture, dès aujourd'hui.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}