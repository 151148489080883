export interface SciFiBook {
  title: string;
  author: string;
  cover?: string;
  summary: string;
  link?: string;
}

export const scifiBooks: SciFiBook[] = [
  {
    title: "Dune",
    author: "Frank Herbert",
    cover: "https://m.media-amazon.com/images/I/61HLU-TCZ8L._SY466_.jpg",
    summary: "Sur la planète désertique Arrakis, un jeune héros, Paul Atréides, découvre sa destinée au milieu de complots politiques, de batailles épiques et de mysticismes liés à une ressource rare : l'épice. Véritable chef-d'œuvre, Dune explore des thèmes tels que la religion, l'écologie et le pouvoir.",
    link: "https://www.amazon.fr/Dune-1-Frank-HERBERT/dp/2266320483"
  },
  {
    title: "Fondation",
    author: "Isaac Asimov",
    summary: "Hari Seldon, un mathématicien visionnaire, prévoit la chute de l'Empire galactique et met en place une \"Fondation\" pour préserver le savoir humain et accélérer la renaissance civilisationnelle."
  },
  {
    title: "1984",
    author: "George Orwell",
    summary: "Dans un monde dystopique où Big Brother surveille tout, Winston Smith lutte contre un régime totalitaire qui contrôle la pensée et manipule la réalité."
  },
  {
    title: "La Guerre des Mondes",
    author: "H.G. Wells",
    summary: "Une invasion extraterrestre menace la survie de l'humanité. Ce roman novateur raconte la terreur et l'ingéniosité humaine face à l'inconnu."
  },
  {
    title: "Neuromancien",
    author: "William Gibson",
    summary: "Case, un pirate informatique au bord du désespoir, est embauché pour une mission qui pourrait changer à jamais le monde numérique. Ce roman est l'une des pierres angulaires du cyberpunk."
  },
  {
    title: "Les Robots",
    author: "Isaac Asimov",
    summary: "Une série de nouvelles explorant les interactions entre humains et robots, régies par les fameuses \"Trois lois de la robotique\"."
  },
  {
    title: "Fahrenheit 451",
    author: "Ray Bradbury",
    summary: "Dans une société où les livres sont interdits et brûlés, un pompier, Guy Montag, découvre le pouvoir de la littérature et se révolte contre le système."
  },
  {
    title: "Hyperion",
    author: "Dan Simmons",
    summary: "Un groupe de sept pèlerins entreprend un voyage mystérieux vers les Tombeaux du Temps, où les attend une entité énigmatique, le Shrike. Chaque personnage raconte son histoire, révélant des intrigues complexes."
  },
  {
    title: "Le Meilleur des Mondes",
    author: "Aldous Huxley",
    summary: "Dans une société utopique basée sur le contrôle total, les individus vivent dans une illusion de bonheur, mais à quel prix ?"
  },
  {
    title: "Solaris",
    author: "Stanisław Lem",
    summary: "Sur une planète mystérieuse, Solaris, un océan vivant confronte les explorateurs"
  }
];