import React from 'react';
import { ChevronRight } from 'lucide-react';
import type { CategoryGroup } from '../data/categories';

interface CategoryGroupCardProps {
  group: CategoryGroup;
}

export default function CategoryGroupCard({ group }: CategoryGroupCardProps) {
  const isClickable = group.isAvailable;
  const Component = isClickable ? 'a' : 'div';
  const props = isClickable ? { href: `/categories/${group.slug}` } : {};

  return (
    <Component
      {...props}
      className={`block bg-gray-800/50 rounded-2xl p-8 backdrop-blur-sm border border-gray-700 
        ${isClickable ? 'hover:border-blue-500/50 transition-all duration-200 group cursor-pointer' : 'opacity-75 cursor-not-allowed'}`}
    >
      <div className="flex justify-between items-start">
        <div>
          <h3 className={`text-xl font-bold mb-3 ${isClickable ? 'text-white group-hover:text-blue-400 transition-colors' : 'text-gray-300'}`}>
            {group.title}
          </h3>
          <p className="text-gray-400 leading-relaxed">
            {group.description}
          </p>
          {!isClickable && (
            <p className="text-gray-500 text-sm mt-4 italic">
              Bientôt disponible
            </p>
          )}
        </div>
        {isClickable && (
          <ChevronRight className="w-6 h-6 text-gray-400 group-hover:text-blue-400 transition-colors" />
        )}
      </div>
    </Component>
  );
}