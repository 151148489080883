import React from 'react';
import { ArrowLeft } from 'lucide-react';

export default function LegalPage() {
  return (
    <main className="flex-grow container mx-auto px-4 py-16">
      <div className="max-w-3xl mx-auto">
        <div className="flex items-center justify-between mb-8">
          <h1 className="text-4xl font-bold text-white">Mentions légales</h1>
          <a 
            href="/" 
            className="flex items-center text-gray-400 hover:text-blue-400 transition-colors duration-200"
          >
            <ArrowLeft className="w-5 h-5 mr-2" />
            Retour à l'accueil
          </a>
        </div>
        
        <div className="space-y-8">
          <section className="bg-gray-800/50 rounded-2xl p-8 backdrop-blur-sm border border-gray-700">
            <h2 className="text-2xl font-semibold text-white mb-4">Éditeur du site</h2>
            <div className="space-y-2 text-gray-300">
              <p><strong className="text-white">Nom du site :</strong> BookFinder.io</p>
              <p><strong className="text-white">Responsable de la publication :</strong> [Votre nom ou celui de votre entreprise]</p>
              <p><strong className="text-white">Contact :</strong> [Votre adresse e-mail]</p>
              <p><strong className="text-white">Statut juridique :</strong> [Ex. : Auto-entrepreneur, SARL, SAS, etc.]</p>
              <p><strong className="text-white">Adresse :</strong> [Votre adresse postale complète]</p>
            </div>
          </section>

          <section className="bg-gray-800/50 rounded-2xl p-8 backdrop-blur-sm border border-gray-700">
            <h2 className="text-2xl font-semibold text-white mb-4">Hébergeur</h2>
            <div className="space-y-2 text-gray-300">
              <p><strong className="text-white">Nom de l'hébergeur :</strong> IONOS</p>
              <p><strong className="text-white">Adresse :</strong> 7 Pl. de la Gare, 57200 Sarreguemines</p>
              <p><strong className="text-white">Site internet :</strong> <a href="https://www.ionos.fr/" target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-300 transition-colors">www.ionos.fr</a></p>
            </div>
          </section>

          <section className="bg-gray-800/50 rounded-2xl p-8 backdrop-blur-sm border border-gray-700">
            <h2 className="text-2xl font-semibold text-white mb-4">Propriété intellectuelle</h2>
            <p className="text-gray-300">
              Le contenu de ce site (textes, images, design) est protégé par le droit d'auteur. 
              Toute reproduction est interdite sans autorisation préalable.
            </p>
          </section>

          <section className="bg-gray-800/50 rounded-2xl p-8 backdrop-blur-sm border border-gray-700">
            <h2 className="text-2xl font-semibold text-white mb-4">Liens affiliés Amazon</h2>
            <p className="text-gray-300">
              Ce site participe au programme d'affiliation Amazon. Certains liens présents redirigent 
              vers Amazon, et une commission peut être perçue si un achat est effectué, sans coût 
              supplémentaire pour vous.
            </p>
          </section>
        </div>
      </div>
    </main>
  );
}