import React from 'react';
import { ArrowLeft } from 'lucide-react';
import BookList from '../components/BookList';
import { scifiBooks } from '../data/scifi-books';

export default function SciFiPage() {
  return (
    <main className="flex-grow container mx-auto px-4 py-16">
      <div className="max-w-4xl mx-auto">
        <div className="flex items-center justify-between mb-8">
          <h1 className="text-4xl font-bold text-white">Les meilleurs livres de science-fiction</h1>
          <a 
            href="/selection" 
            className="flex items-center text-gray-400 hover:text-blue-400 transition-colors duration-200"
          >
            <ArrowLeft className="w-5 h-5 mr-2" />
            Retour aux catégories
          </a>
        </div>

        <div className="space-y-8 mb-16">
          <div className="bg-gray-800/50 rounded-2xl p-8 backdrop-blur-sm border border-gray-700">
            <h2 className="text-2xl font-semibold text-white mb-6">
              Découvrez les meilleurs livres de science-fiction : une aventure littéraire incontournable
            </h2>
            
            <div className="space-y-6 text-gray-300 leading-relaxed">
              <p>
                La science-fiction est un genre fascinant qui transporte ses lecteurs dans des mondes 
                futuristes, des galaxies lointaines ou des réalités alternatives. À travers des 
                histoires captivantes, elle explore des thèmes universels comme la technologie, 
                l'humanité et les limites de l'imaginaire. Vous êtes à la recherche d'un livre de 
                science-fiction à dévorer ? Que vous soyez novice dans le genre ou un passionné 
                aguerri, notre sélection des meilleurs livres de science-fiction vous aidera à faire 
                le bon choix.
              </p>

              <p>
                Du classique intemporel comme <a href="https://www.amazon.fr/Dune-1-Frank-HERBERT/dp/2266320483" target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-300 transition-colors">Dune de Frank Herbert</a> aux récits visionnaires comme <a href="https://www.amazon.fr/Probl%C3%A8me-%C3%A0-trois-corps/dp/2330181051" target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-300 transition-colors">Le Problème à trois corps de Liu Cixin</a>, les romans de science-fiction offrent une 
                richesse d'histoires et de réflexions qui éveillent la curiosité et stimulent 
                l'esprit. Que vous recherchiez des idées de lecture pour découvrir les 
                incontournables du genre ou des recommandations personnalisées, notre classement des 
                meilleurs livres de science-fiction répondra à vos attentes.
              </p>

              <p>
                Pourquoi choisir un bon roman de science-fiction ? Ce genre littéraire permet non 
                seulement de s'évader mais aussi de réfléchir aux enjeux de notre monde : 
                intelligence artificielle, exploration spatiale, écologie ou relations humaines. En 
                plongeant dans ces récits, vous explorerez des mondes complexes et découvrirez des 
                personnages marquants qui défient les conventions et interrogent notre avenir.
              </p>

              <p>
                Explorez notre classement des meilleurs livres de science-fiction et laissez-vous 
                guider par notre sélection soigneusement élaborée. Des chefs-d'œuvre classiques aux 
                nouveautés incontournables, trouvez votre prochaine lecture pour voyager au-delà des 
                étoiles, explorer des univers parallèles ou défier les lois du temps.
              </p>

              <p>
                Faites le plein d'inspiration ! Si vous cherchez des idées de livres de 
                science-fiction à lire ou à offrir, notre guide est là pour vous accompagner. 
                Rejoignez l'aventure et trouvez dès maintenant votre prochain coup de cœur 
                littéraire !
              </p>
            </div>
          </div>
        </div>

        <BookList books={scifiBooks} />
      </div>
    </main>
  );
}